.login {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 382px;
  overflow: hidden;
  margin: auto;
  margin: 20 0 0 450px;
  padding: 80px;
  background: #23463f;
  border-radius: 15px;
}

h2 {
  text-align: center;
  color: #277582;
  padding: 20px;
}

.label {
  color: #08ffd1;
  font-size: 17px;
}

#Uname {
  width: 220px;
  height: 30px;
  border: none;
  border-radius: 3px;
  padding-left: 8px;
}

#Pass {
  width: 220px;
  height: 30px;
  border: none;
  border-radius: 3px;
  padding-left: 8px;

}

#log {
  width: 220px;
  height: 30px;
  border: none;
  border-radius: 17px;
  padding-left: 7px;
  color: blue;
}

span {
  color: white;
  font-size: 17px;
  margin-right: 5px;
}

.formLabel,
.loginHeading {
  font-family: "Poppins";
}

.formLabel {
  color: #08ffd1;
}

.email input,
.password input {
  height: 40px;
  padding: 0px 10px;
  border: lightgrey 1px solid;
  border-radius: 15px;
  font-size: 14px;
  font-family: "poppins";
  text-transform: none;
}

.font-14 {
  font-size: 14px !important;
}

.togglePassword {
  position: absolute;
  right: 0;
}

.forgotLink {
  margin: 4px 0 !important;
}

.login-btn,
.pwa-btn,
.bookBtn {
  font-size: 14px !important;
  border-radius: 15px !important;
  background-color: #304FFD !important;
}

button:disabled,
button[disabled] {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.signupText {
  font-size: 14px !important;
  padding: 2px 3px;
}

.MuiTextField-root p {
  color: red !important;
  font-size: 10px;
}

.forgotPassowrd {
  font-weight: 400;
  font-size: 14px !important;
  color: #304FFD !important;
  text-decoration: none;
}

.footerContainer {
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  left: 0;
}

.signup-background {
  background-image: url("../../assets/signup_laptop.png");
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 100vh
}

.forgot-background {
  background-image: url("../../assets/signup_laptop.png");
  background-repeat: no-repeat !important;
  background-size: cover !important;
  overflow-y: scroll;
}

@media (max-width:899px) {
  .forgot-background {
    background-image: url("../../assets/tablet.png");
    height: 100vh !important;
  }

}

@media (max-width:640px) {
  .forgot-background {
    background-image: url("../../assets/mobile.png");
    height: 100vh !important;
  }
}