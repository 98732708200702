@import "rsuite/dist/rsuite.css";

:root {
  --selectedStart: linear-gradient(to right, transparent, transparent 50%, rgba(221, 235, 247, 0.5) 50%);
  --selectedEnd: linear-gradient(to left, transparent, transparent 50%, rgba(221, 235, 247, 0.5) 50%);

}

.custom-event-editor td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.e-OwnerId-container,
.e-input-wrapper.e-form-left,
.e-all-day-container,
.e-location-container {
  display: none;
}

.e-subject-container {
  width: 100% !important;
}

/* .e-work-cells.e-child-node.e-work-days.e-disableCell{
    background-color: #d12a2a42 !important;
} */
/* Style the search field */
form.example {
  width: 250px;
  position: fixed !important;
  z-index: 1;
  top: 30px;
}

form.example input[type=text] {
  padding: 10px;
  padding-left: 4rem;
  font-size: 17px;
  border: 1px solid transparent;
  border-radius: 10px;
  float: left;
  background: #F8F8F8;
  width: 92%;
  height: 45px;
  margin-top: 27px;
  margin-left: 10px;
  margin-right: 10px;
}


/* Clear floats */
form.example::after {
  content: "";
  clear: both;
  display: table;
}


.e-work-cells.e-child-node.e-work-days.backendClass {
  /* background: url("../../assets/SS.png") !important; */
  background: repeating-linear-gradient(45deg, rgb(196, 188, 188), white 2px, white 2px, white 8px) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  cursor: not-allowed !important;
}

.resource-name a {
  background-color: transparent !important;
  float: left !important;
}

.resource-name {
  margin-top: 11px;
  margin-left: 1px !important;
}

.freelacner-name,
.freelacner-clickableName {
  width: 107px;
  height: 27px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: black;
  /* color: #3F434A; */
}

.freelacner-clickableName:hover {
  color: rgb(72, 136, 255) !important;
}

.freelacner-name:hover {
  color: black !important;
}

.e-date-range:hover>button>span>span.header_year {
  color: rgb(72, 136, 255) !important;
}

.client_profession,
.mint_association {
  position: relative;
  width: 230px !important;
  height: 50px;
  /* left: -107px; */
  top: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: #828282;
}

.professtion_ {
  height: 21px;
}

.day-rate {
  width: 53px;
  height: 18px;
  margin-top: 20px;
  margin-bottom: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #828282;
}

.customBtn {
  position: absolute;
  top: 42px;
  left: 22px;
  font-size: 18px;
}

.customFilterBtn {
  position: absolute;
  top: 42px;
  left: 210px;
  font-size: 18px;
}

.ratings {
  position: relative;
  top: 5px;
  left: 30% !important;
}

.topday {
  width: 25px;
  text-align: center;
  text-align: center;
  text-transform: uppercase !important;
  color: #8A9099;
  font-size: 12px;
  line-height: 18px;
  font-family: Poppins !important;
}

.topdayContent {
  position: relative;
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 6px;
  margin-left: -2px;
  border-radius: 10px;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
}


.e-schedule .e-timeline-view .e-appointment,
.e-schedule .e-timeline-month-view .e-appointment {
  max-height: 500px;
  max-width: 500px;
  margin-left: 4px;
  margin-top: 1.5px;
}

.e-appointment .blurred {
  filter: blur(1px);
}

#ScheduleComponent {
  position: absolute;
}

#logout-button {
  position: relative;
  float: right;
  top: 11px;
  right: -1rem;
  flex-direction: row !important;
  display: flex !important;
}

#btn {
  border: none;
  padding: 5px 15px;
  background-color: transparent;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif";
  ;
  /* font-family: "Poppins"; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #8A9099;
}

#btn:hover {
  background-color: transparent !important;
  color: #4888ff !important;
  /* background-color: rgb(224, 224, 224); */
}

.e-appointment {
  background-color: transparent !important;
}

#whatsapp-button {
  position: absolute;
  float: right;
  top: 93.2%;
  right: -28px;
}

#whatsapp-btn {
  position: fixed;
}

.e-busy-weekend {
  /* background: url("../../assets/SS.png") !important; */
  background: repeating-linear-gradient(45deg, rgb(196, 188, 188), white 2px, white 2px, white 8px) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.e-schedule .e-timeline-month-view .e-appointment,
.e-schedule .e-timeline-view .e-appointment {
  height: 85px !important;
  /* top:114px !important */
}

.e-schedule-table .e-content-table {
  border-bottom: 1px solid lightgray;
}

.e-weekend {
  background-color: #ccc;
}

.e-icons {
  color: #3F434A !important;
  font-size: 9px !important;
}

.e-work-cells.e-child-node.e-work-days.client_view {
  min-height: 87px !important;
  height: 87px !important;
}

.e-schedule .e-timeline-view .e-work-cells.non-active-day,
.e-schedule .e-timeline-month-view .e-work-cells.non-active-day {
  background-color: #F2F2F3;
}

.e-schedule .e-timeline-view .e-work-cells.active-day,
.e-schedule .e-timeline-month-view .e-work-cells.active-day {
  background-color: #ffffff;
}

.e-work-cells.e-child-node.client_view {
  height: 87px !important;
}

.e-tbar-btn-text {
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #3F434A;
}

.e-schedule-table>colgroup>col {
  width: 87px !important;
}

.e-header-cells.e-date-header {
  border-right-color: white !important;
  border-left-color: white !important;
}

.e-resource-cells.e-child-node.client {
  height: 80px !important;
}

.e-toolbar-items.e-tbar-pos {
  background-color: white !important;
}

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: rgb(72, 136, 255) !important;
}

.e-toolbar-item.e-date-range.e-overflow-show :hover {
  background-color: transparent !important;
}

.e-schedule .e-timeline-view .e-appointment,
.e-schedule .e-timeline-month-view .e-appointment {
  border-radius: 4px !important;
  min-width: fit-content;
}

.e-btn.e-today.e-flat.e-primary.e-css {
  color: rgb(72, 136, 255) !important;
  font-family: 'Poppins';
}

.e-btn.e-today.e-flat.e-primary.e-css:focus,
.e-btn.e-today.e-flat.e-primary.e-css:active,
.e-btn.e-today.e-flat.e-primary.e-css:hover {
  background-color: #E8E8E7 !important;
}

.has-query .customBtn path,
.searchInput:focus + .customBtn path {
  fill: #007AFF !important;
}

.searchInput,
.searchInput::placeholder {
  font-family: 'Poppins';
}

.searchInput::placeholder {
  color: #8E8E93;
  text-transform: none;
}

.has-query .searchInput, 
.searchInput:focus {
  outline: #007AFF;
  outline-width: 1px;
  border-color: #007AFF !important;
  color: #3F434A;
}

.header_year {
  font-size: 14px;
  color: #8A9099 !important;
  padding-left: 10px !important;
}

.e-btn-icon.e-icon-down-arrow.e-icons.e-icon-right {
  margin-left: -15px !important;
}

td:focus {
  background-color: transparent !important;
}

.e-schedule .e-timeline-view .e-appointment.e-appointment-border,
.e-schedule .e-timeline-view .e-appointment:focus,
.e-schedule .e-timeline-month-view .e-appointment.e-appointment-border,
.e-schedule .e-timeline-month-view .e-appointment:focus {
  border: 0 !important;
  box-shadow: none !important;
}

.e-schedule .e-schedule-toolbar {
  box-shadow: none !important;
  border: 1px solid !important;
  border-color: transparent transparent #e0e0e0 transparent !important;
}

.e-work-cells:hover {
  background-color: #e0e0e0 !important;
}

.pending:hover,
.accepted:hover,
.optional:hover {
  background-color: transparent !important;
}

.e-schedule .e-schedule-toolbar {
  margin-bottom: 0px !important;
}

.invitePopupTitle {
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  color: #3F434A !important;
  font-family: 'poppins';
}

.invitationForm {
  flex-direction: row !important;
}

.inviteField {
  width: 100% !important;
  margin: 15px 10px 20px 0 !important;
}

fieldset {
  border: none !important;
  outline: none !important;
  border: 1px solid #e0e0e0;
}


.inviteField .MuiOutlinedInput-root {
  border: 1px solid #e0e0e0;
  height: 42px;
  margin-top: 8px;
  border-radius: 15px !important;
}

.inviteField .MuiInputLabel-root {
  transform: translate(0px, -9px) scale(0.75) !important;
}

.sendBtn {
  height: 40px;
  width: 40px;
  min-width: 40px !important;
  margin: 30px 0px 0px 12px !important;
  border-radius: 13px !important;
  background-color: white !important;
  border-radius: 50% !important;
}

.sendAvatar {
  height: 50px !important;
  width: 50px !important;
}

.sendBtn .MuiTouchRipple-root {
  margin-right: 0;
}

.checkAvatar {
  height: 20px !important;
  width: 20px !important;
}

.MuiLoadingButton-loadingIndicatorCenter span {
  color: darkgray !important;
  margin: 0;
  height: 22px !important;
  width: 22px !important;
}

.invitationFormText {
  font-size: 14px !important;
  color: #8A9099 !important;
}

::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.invitedPersonRow {
  padding: 8px 9px;
  border: 1px solid #E8E9EB;
  border-radius: 15px;
}

.listItem span,
.client_profession {
  font-size: 14px;
  color: #8A9099;
  flex: 1;
  width: 200px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}


.listItemLink {
  font-size: 14px !important;
  color: #8A9099 !important;
  cursor: pointer;
  text-decoration: none;
}

/* .tickSign {
  display: flex !important;
  flex-direction: row-reverse !important;
  align-items: center;
} */

.emailInviteField {
  text-decoration: underline !important;

}

.closeBar {
  margin-bottom: -50px !important;
}

.closeButton {
  width: 30px !important;
  display: flex;
  margin: 15px !important;
  padding: 6px !important;
  border-radius: 10px !important;
  height: 30px !important;
  cursor: pointer;
}

.closeButton:hover {
  background-color: #F8F8F8 !important;
}

.inivitationDialog .MuiDialog-container .MuiDialog-paper {
  border-radius: 15px !important;
  height: auto !important;
}

.notificationDialog {
  position: absolute !important;
  left: 10 !important;
  top: 50 !important;
}


.invitaitionCardBody {
  font-size: 18px;
  font-weight: '500';
  text-align: left;
  color: #3F434A;
}

.e-schedule .e-header-calendar {
  box-shadow: rgb(0 0 0 / 20%) 0px 18px 60px -10px !important;
}

.innerParagraph {
  margin: 10px 0 0 !important;
}

.shareAbleLinkinvitaitionCardBody {
  font-size: 18px;
  font-weight: '500';
  text-align: left;
  color: #3F434A;
  text-transform: none !important;
}

.invitaitionCardLink {
  font-size: 18px !important;
  font-weight: '500' !important;
  color: #304FFD !important;
}

.bellIcon {
  cursor: pointer;
  width: 33px !important;
  height: 35px !important;
  color: #3F434A;
  padding: 6px !important;
  border-radius: 10px !important;
}

.bellDot {
  position: absolute !important;
  top: 3px !important;
  right: 52px !important;
  max-width: 9px;
  color: #FD7972;
  cursor: pointer;
}

.menuIcon {
  margin-left: 5px;
  margin-top: 2px;
  cursor: pointer;
  width: 30.5px !important;
  height: 30px !important;
  padding: 6px !important;
  border-radius: 10px !important;
}

.senderName {
  height: 20px;
  font-size: 15px !important;
  color: #3F434A !important;
  flex: 1;
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  font-weight: 400;
  font-style: normal;
  text-overflow: ellipsis;
}

.senderStatus {
  font-size: 11px !important;
  font-weight: 400 !important;
  color: #8A9099 !important;
  padding-top: 15 !important;
}

/* .senderMessage {
  font-size: 12px;
  font-weight: 500;
  color: #8A9099 !important;
  padding-top: 15 !important;
  padding-left: 10px !important;
} */

#menu-appbar .MuiPaper-root {
  width: 300px !important;
  height: 400px !important;
  border-radius: 12px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 60px -10px !important;
  /* box-shadow: rgba(232, 233, 235, 1) 0px 2px 1px, #E8E9EB 0px 4px 12px, #E8E9EB 0px 8px 24px, #E8E9EB 0px 16px 8px, #E8E9EB 0px 15px 30px !important; */
  /* box-shadow: #E8E9EB 0px 2px 1px, 5px 5px 20px 10px #E8E9EB !important; */
  /* box-shadow: #E8E9EB 0px 3px 28px !important; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 27px -1px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important; */

}

#menu-appbarfixed .MuiPaper-root {
  position: fixed;
  width: 150px !important;
  height: auto !important;
  margin-top: 25px;
  border-radius: 12px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 60px -10px !important;
}

#menu-appbar1 .MuiPaper-root {
  width: 300px !important;
  border-radius: 12px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 60px -10px !important;
  margin-left: 0px;
  /* box-shadow: rgba(232, 233, 235, 1) 0px 2px 1px, #E8E9EB 0px 4px 12px, #E8E9EB 0px 8px 24px, #E8E9EB 0px 16px 8px, #E8E9EB 0px 15px 30px !important; */
  /* box-shadow: #E8E9EB 0px 2px 1px, 5px 5px 20px 10px #E8E9EB !important; */
  /* box-shadow: #E8E9EB 0px 3px 28px !important; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 27px -1px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important; */
  top: 0px !important
}

.dropdownMenu {
  height: 100%;
}

.dropdownMenuHeaderContainer {
  position: fixed !important;
  width: 300px !important;
  background-color: white !important;
  border-radius: 12px !important;
  z-index: 2 !important;
}

.dropdownMenuHeader {
  padding: 10px 20px 10px 20px;
  display: flex;
  width: 100%;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.dropdownMenuHeading {
  font-size: 15.5px !important;
  font-weight: 600 !important;
  color: #3F434A !important;
}

/* .deleteButton {
  background-color: #E8E9EB !important;
  width: 25px !important;
  display: flex;
  margin: 9px 2px 9px 9px;
  padding: 6px !important;
  border-radius: 10px !important;
  height: 25px !important;
  cursor: pointer;
} */

.notificationPopup .MuiPopover-paper ul {
  padding-top: 0px;
}

.norificationsCount {
  color: white !important;
  background-color: #FD7972 !important;
  width: 18.85px !important;
  height: 19px !important;
  border-radius: 7px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600 !important;
  font-size: 10px !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
}

.notificationContainer {
  justify-content: center;
}

.notificationDateTitle {
  font-size: 13px !important;
  justify-content: center;
  margin: 7px 0 0 0 !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #8A9099 !important;
}

.singleNotification {
  align-items: center;
  justify-self: center;
  display: flex;
}

.notificationContent {
  padding: 0 6px;
}

/* .notificationModule {
  display: flex;
  align-items: center;
} */

.singleNotification {
  padding: 7px 7px 7px 7px !important;
}

.singleNotification:hover {
  background-color: #F2F2F2;
}

.notificationsList {
  width: '100%' !important;
  max-width: 360 !important;
  height: 400px !important;
}

.inviteAlert {
  position: absolute !important;
  border-radius: 0 !important;
  height: 47px;
}

.menuLink {
  text-decoration: none !important;
  color: #3F434A !important;
  font-size: 14px !important;
}

.css-l9bevp-MuiPaper-root-MuiDialog-paper {
  width: 859px !important;
  height: 63% !important;
}

.topHederResource {
  display: flex;
}

.bookingModal {
  border-radius: 20px;
  width: 400px !important;
  z-index: 50 !important;
}

.bookingModal .rs-picker-daterange-menu {
  z-index: 200 !important;
}

/* .css-79ws1d-MuiModal-root {
  z-index: 1 !important;
} */


.bookingName {
  color: #3F434A !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  word-wrap: break-word;
  text-align: center;
}

.monthName {
  color: #3F434A !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  word-wrap: break-word;
  text-align: center;
}

.monthNameSub {
  color: #8A9099 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.rowAlign {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.columnAlign {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* margin-top: -25px !important; */
}

.timeSettingFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingInputField input {
  text-align: center;
  height: 40px;
  width: 118px;
  padding: 0px 10px;
  border: lightgrey 1px solid;
  border-radius: 15px;
  font-size: 14px;
  font-family: "poppins";
  justify-content: space-between !important;
  color: #3F434A !important;
}

.bookingCloseButton {
  width: 30px !important;
  display: flex;
  margin: 15px;
  padding: 6px;
  border-radius: 10px;
  height: 30px !important;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.bookingCloseButton:hover {
  background-color: #F8F8F8 !important;
}

.bookingCard {
  margin: 0 20px;
}

.bookingProjectName {
  margin-top: 7px !important;
  margin-bottom: 0 !important;
}

.bookingDescription {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
}

.bookingProjectName input,
.bookingDescription textarea {
  height: 40px;
  width: 100% !important;
  padding: 0px 14px;
  border: lightgrey 1px solid !important;
  border-bottom: none !important;
  color: #3F434A;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  font-size: 15px;
  font-weight: 600;
  font-family: "poppins";
  justify-content: space-between !important;
  margin-top: 0 !important;
}

.bookingDescription ::placeholder,
.bookingProjectName ::placeholder {
  color: black !important;
}

.bookingDescription textarea {
  padding: 0px 14px 13px 14px;
  border: lightgrey 1px solid !important;
  font-size: 12px;
  font-weight: 400;
  border-top: none !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.bookingDescription .MuiInputBase-multiline {
  padding-top: 0;
}

.timeRange {
  text-align: center;
  display: flex;
  height: fit-content;
  width: 170px;
  border: lightgrey 1px solid;
  border-radius: 15px;
  font-size: 14px;
  font-family: "poppins";
  align-items: center !important;
  justify-content: center !important;
}

.timeRange .component {
  padding: 0px !important;
  overflow: hidden !important;
}

.timeRange select {
  border: none !important;
  background-color: white !important;
}

.timeRange .component .label {
  font-size: 14px;
  color: #3F434A;
  padding: 0 !important;
}

.bookingCard .bookingLocation {
  display: none;
}

.bookingLocation {
  margin-top: 2px !important;
}

.bookingLocation input {
  width: 100%;
  color: #304FFD;
  text-align: start;
  margin-bottom: 15px;
}

.bookingTotal {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #3F434A !important;
}

.addLocationCheckbox :checked {
  color: #304FFD !important;
  border-radius: 12px !important;
}

.bookingCheckBox {
  font-size: 12px !important;
  color: #8A9099 !important;
}

.bookingCheck {
  direction: row;
  display: flex;
  align-items: center;
}

.timeRange .error {
  display: none;
}

.errorRow {
  display: flex;
  justify-content: space-between;
  display: none;
}

.errorMsg {
  width: 100%;
  padding: 0 10px;
  margin: 0;
  color: red;
}

.bookBtn,
.bookingCancelBtn {
  padding: 8px 0 !important;
  width: 125px !important;
  margin: 0 auto 5px auto !important;
  display: block !important;
}

.transparentbtn {
  padding: 8px 0 !important;
  width: 125px !important;
  /* margin: 0 auto 5px auto !important; */
  border-radius: 15px !important;
  border-color: #E8E9EB !important;
  background-color: #ffffff !important;
}

.bookingCancelBtn {
  background-color: #F05C54 !important;
  font-size: 14px !important;
  border-radius: 15px !important;
  color: white;
}

.archiveCancelBtn {
  padding: 8px 0 !important;
  width: 125px !important;
  display: block !important;
  background-color: #FD7972 !important;
  font-size: 14px !important;
  border-radius: 15px !important;
}

.tooltipTxt {
  font-size: 12px;
}

.bookBtnText {
  color: rgb(247, 247, 247) !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.optionBtnContainer {
  display: flex;
  justify-content: center;
}

.optionBtn,
.cancelOptionBtn {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #2F80ED !important;
}

.optionBtn:hover,
.optionBtn:disabled,
.optionBtn:active,
.cancelOptionBtn:hover,
.cancelOptionBtn:disabled,
cancelOptionBtn:active {
  background-color: transparent !important;
}

.optionInfoBtn {
  position: relative;
  top: 9px;
  left: -4px;
  padding-left: 0 !important;
}

.cancelOptionBtn {
  color: #FD7972 !important;
}

.transparentBtnText {
  color: #595f69 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: 'Poppins' !important;
  line-height: 21px !important;

}

.hoursLabel {
  font-size: 14px !important;
  margin: 0 0 5px 0 !important;
  padding: 0 !important;
  color: #8A9099;
}

.datesLabel {
  font-size: 14px !important;
  margin: 0 0 5px 0 !important;
  padding-top: 5px !important;
  color: #8A9099;
}

.customTimeSelector {
  margin-top: 5px;
  font-family: Poppins;
  color: #3F434A;
}

.bookingAlert {
  left: 0;
  padding: 0 5px !important;
}

.bookingCurrency {
  width: 100% !important;
  top: 12px;
  left: 1px;
}

.bookingCurrency .MuiOutlinedInput-root {
  width: 79px;
  height: 42px;
  display: flex;
  border: lightgrey 1px solid;
  border-left: none;
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  justify-content: center !important;
  font-size: 15px !important;
  color: #3F434A !important;
  margin-left: -20px;
}

.regisBookingCurrency {
  width: 100% !important;
  top: 12px;
  left: 1px;
}

.regisBookingCurrency .MuiOutlinedInput-root {
  height: 40px;
  padding: 0px 10px;
  border: lightgrey 1px solid;
  border-radius: 15px;
  font-size: 14px;
  font-family: "poppins";
}

.ratePerDay input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.ratePerDay {
  width: 100%;
}

.ratePerDay input {
  height: 40px;
  width: 140px;
  display: flex;
  padding: 0px 10px;
  border: lightgrey 1px solid;
  border-radius: 15px !important;
  font-size: 15px;
  color: #3F434A !important;
  padding-left: 62px;
  box-sizing: border-box;
  font-weight: 400;
}

.ratePerDayPad input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.ratePerDayPad {
  width: 100%;
}

.ratePerDayPad input {
  height: 40px;
  width: 140px;
  display: flex;
  padding: 0px 10px;
  border: lightgrey 1px solid;
  border-radius: 15px !important;
  font-size: 15px;
  color: #3F434A !important;
  padding-left: 70px;
  box-sizing: border-box;
  font-weight: 400;
}

.mintLogo1 {
  position: absolute !important;
  right: 7px;
  bottom: 12px;
}

.waParagraph {
  align-self: center;
  margin: auto 9px auto 0;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8A9099;
  text-transform: none;
}

.waWrapper a {
  display: flex;
}

.waWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 15px 0 15px 0;
}

.waLogo {
  cursor: pointer;
}

.displayNameOnAcceptedBooking,
.displayNameOnPendingBooking,
.timeTxt {
  margin: 0;
  margin-left: 5px;
  position: absolute;
  top: 20%;
  transform: translateY(-50%);
  color: white;
  font-family: 'Poppins';
  font-size: 12px;
  white-space: nowrap;
  width: 92%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timeTxt {
  top: 80%;
  font-size: 10px;
}

.displayNameOnPendingBooking {
  font-weight: bold;
}

input,
#profession {
  text-transform: capitalize !important;
}

#email,
#password,
#confirmPassword,
#link,
#invitaitionCardBody,
#invitaitionCardLink {
  text-transform: none !important;
}

.alert div {
  text-transform: none !important;
}

.e-work-cells,
.e-schedule .e-timeline-month-view .e-work-cells {
  border-color: #E8E9EB !important;
  border-style: solid !important;
}

.rowAlign {
  margin-bottom: 2px;
}

.e-resource-column-wrap {
  overflow: auto !important;
}

.e-schedule-table.e-resource-column-table.e-auto-height tbody tr {
  height: 87px !important;
  min-height: 87px !important;
  box-sizing: border-box !important;
}

.e-schedule-table.e-resource-column-table.e-auto-height tbody tr:last-child {
  height: 88px !important;
  min-height: 88px !important;
  box-sizing: border-box !important;
}

.e-resource-cells.e-child-node.client {
  height: 87px !important;
  min-height: 87px !important;
  box-sizing: border-box !important;
}

.e-work-cells.e-child-node.e-work-days.client_view {
  height: 87px !important;
  min-height: 87px !important;
  box-sizing: border-box !important;
}

.e-schedule-table.e-content-table.e-auto-height tbody tr {
  height: 87px !important;
  min-height: 87px !important;
  box-sizing: border-box !important;
}

.customSkeleton {
  position: absolute;
  top: 120px;
  overflow-x: hidden !important;
}

.skeletonColor {
  background-color: #eff0f0 !important;
  border-radius: 5px;
}

.skeletonLefColumn {
  flex-basis: 88.333333% !important;
  max-width: 19% !important;
  margin-left: 8px !important;
}

.skeletonRightColumn {
  max-width: 6.66% !important;
}

#root .css-1nqoad0 {
  z-index: 2 !important;
  right: auto;
  height: 50px !important;
  position: fixed !important;
}

.rs-calendar-table-cell-content span {
  color: #3F434A !important;
  font-size: 14px !important;
}

.rs-picker-toggle-wrapper {
  text-align: center;
  height: 42px;
  width: auto;
  padding: 0px 10px;
  border: lightgrey 1px solid;
  border-radius: 15px;
  font-size: 14px;
  font-family: "poppins";
  justify-content: space-between !important;
  color: #3F434A !important;
}

.rs-picker-toggle-textbox {
  font-size: 14px !important;
  font-family: "poppins" !important;
  background-color: transparent;
  color: #3F434A !important;
  width: max-content;
}

.rs-picker-toggle-textbox::placeholder {
  font-size: 14px !important;
  font-family: "poppins" !important;
  color: #3F434A !important;
}

.rs-picker-toggle-value {
  font-size: 14px !important;
  font-family: "poppins" !important;
  color: #3F434A !important;
}

.rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle {
  background-color: transparent !important;
}

.rs-btn-default {
  border: none !important;
}

rs-picker-default:hover {
  border: none !important;
  outline: none !important;
}

.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content {
  color: white;
  text-decoration: none;

}

.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content span {
  color: #C6C9CC !important;
  font-size: 14px !important;
}

.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content span:hover {
  color: #C6C9CC !important;
  font-size: 14px !important;
  text-decoration: none !important;
}

.rs-picker-toggle-clean {
  display: none;
}

.rs-picker-toggle-clean:hover {
  display: none;
}

.rs-picker:not(.rs-picker-disabled):hover,
.rs-picker.rs-picker-focused {
  border-color: lightgrey;

}

.rs-calendar-month-dropdown-row-wrapper {
  color: #3F434A;
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content {
  color: #3F434A;
}

.rs-btn-default:active,
.rs-btn-default:focus {
  outline: none !important;
  border-color: #719ECE;
  box-shadow: 0 0 10px #719ECE;
}

.rs-picker-toggle-active,
.rs-btn-default:active {
  box-shadow: none;
}

.rs-picker-toggle-wrapper {
  padding: 4px 0px;
  background: transparent;
}

.rs-stack {
  width: fit-content;
}

.rs-picker-toggle-value div {
  width: 120px !important;
  text-align: center !important;
}

.rs-picker-menu {
  z-index: 1000000 !important;
  margin-top: 10px !important;
  border-radius: 15px !important;
}

.freelancerActionMenu .MuiMenu-paper {
  max-height: fit-content;
  max-width: fit-content;
  height: fit-content;
  top: 0;
}

.rs-picker-daterange-header {
  display: none;
}

.rs-picker-toolbar-ranges {
  display: none;
}

.rs-picker-toolbar-right {
  margin-left: 110px;
}

.rs-picker-daterange-calendar-single {
  width: 290px;
  font-family: 'Poppins' !important;
  color: black;
  height: 320px !important;
}

.rs-calendar-table-cell-selected-start .rs-calendar-table-cell-content .rs-calendar-table-cell-day {
  color: white !important;
}

.rs-calendar-table-cell-selected-end .rs-calendar-table-cell-content .rs-calendar-table-cell-day {
  color: white !important;
}

.rs-picker-toolbar {
  display: none !important;
}

.rs-calendar-table-cell-content {
  width: 30px;
  height: 30px;
  padding-left: 3px !important;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
  background-color: white !important;
  background-color: #F8F8F8 !important;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
  background-color: #3498ff !important;
  color: white !important;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #3498ff !important;
  color: white !important;
}


.rs-calendar-table-cell-is-today {
  border: none !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: none !important;
}

.rs-calendar-table-cell,
.rs-calendar-table-header-cell {
  text-transform: uppercase !important;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
  background-color: #3498ff !important;
  color: white !important;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #3498ff !important;
  color: white !important;
}

.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content,
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content:hover {
  background: none !important;
  text-decoration: none !important;
  cursor: not-allowed !important;
}

.OptionPopup .MuiDialog-container .MuiPaper-root {
  border-radius: 18px !important;
}

.optionPopupTitle {
  color: #3F434A !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px !important;
}

.optionPopupContent {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #595F69;
}

.optionPopupContentContainer {
  padding-bottom: 5px !important;
}


.optionPopupBtnContainer {
  justify-content: center !important;
  padding-bottom: 24px !important;
}

.optionPopupBtn {
  padding: 20px 57px;
  font-size: 15px !important;
  color: #595F69 !important;
  background: #E8E9EB;
  border: 1px solid #E8E9EB !important;
  border-radius: 12px !important;
}

.e-header-month {
  color: black;
}

.rs-picker-daterange-menu .rs-calendar-header {
  margin-bottom: 30px !important;
  margin-top: 10px !important;
}

.rs-calendar-body {
  padding: 0 !important;
  overflow: visible !important;
}

.rs-calendar {
  height: 320px !important;
}

.logoutComp {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #3F434A !important;
  font-family: 'poppins' !important;
  margin-top: 5px !important;
  width: 100% !important;
  height: 30px !important;
  padding-left: 20px !important;
}


.inviteFree {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #3F434A !important;
  font-family: 'poppins' !important;
  margin-top: 3px !important;
  width: 100% !important;
  height: 30px !important;
  padding-left: 20px !important;
}

.inviteFree:hover,
.logoutComp:hover {
  cursor: pointer;
  background-color: #F8F8F8 !important;
}

.tagLine {
  color: #8A9099;
  font-size: 10px;
  font-weight: 400;
  display: block;
}

@media only screen and (max-width: 700px) {

  #root .css-1nqoad0 {
    top: 24px
  }

  #ScheduleComponent {
    padding-top: 22px;
  }



  form.example {
    width: 150px !important;
  }

  .ratings {
    top: 3.8rem !important;
    left: -10.7rem !important;
    height: 2.5rem !important;
    z-index: 1 !important;
  }

  .menuLink.inviteFreelancer {
    display: none !important;
  }

  .img-settings {
    float: right;
    height: 19px;
    margin-right: 30px;
    margin-top: 7.5px;
  }



  .dropdownMenuHeader {
    margin-top: 7px;
    margin-bottom: 5px;
    padding-right: 15px !important;
  }

  .MuiPopover-paper {
    top: 28px !important;
  }


  .inviteFree,
  .settings,
  .logoutComp,
  .whatApp {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #3F434A !important;
    font-family: 'poppins' !important;
    margin-top: 8px !important;
    width: 100% !important;
    height: 41px !important;
    padding-left: 20px !important;
    font-family: 'Poppins' !important
  }

  .settings-height {
    height: 36px !important;
    font-size: 17px !important;
    margin-bottom: 8px;
    letter-spacing: 0.00938em;
  }

  .bottom-border {
    border-bottom: 1px solid lightgray;
  }

  .inviteFree:hover,
  .whatApp:hover,
  .logoutComp:hover {
    background-color: #F8F8F8 !important;
  }

  #logout-button {
    position: relative;
    float: right;
    top: 2px;
    right: -0.5rem;
    flex-direction: row !important;
    display: flex !important;
  }

  .menuIcon {
    margin-left: 5px;
    cursor: pointer;
    width: 31px !important;
    height: 29px !important;
    padding: 6px !important;
    border-radius: 7px !important;
    margin-right: 5px !important;
  }

  .dropdownMenuHeading {
    font-size: 17px !important;
    font-weight: 400 !important;
  }

  .bellIcon {
    cursor: pointer;
    width: 31px !important;
    height: 31px !important;
    color: #3F434A;
    padding: 4px !important;
    border-radius: 7px !important;
  }

  form.example input[type=text] {
    padding: 10px;
    padding-left: 4rem;
    font-size: 14px;
    border: 1px solid transparent;
    border-radius: 10px;
    float: left;
    background: #F8F8F8;
    width: 92%;
    height: 35px;
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .customBtn {
    position: absolute;
    top: 42px;
    left: 22px;
    font-size: 18px;
  }

  .customFilterBtn {
    position: absolute;
    top: 42px;
    left: 125px;
    font-size: 18px;
  }

  .header_year {
    font-size: 14px;
    color: #8A9099 !important;
    padding-left: 10px !important;
  }

  .e-btn-icon.e-icon-down-arrow.e-icons.e-icon-right {
    margin-left: -15px !important;
  }

  .e-header-month {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #3F434A !important;
    padding: 5px;
    font-family: 'poppins' !important;
    margin: 5px;
  }

  .e-header-month-cell {
    padding: 10px !important;
  }

  .e-toolbar-item.e-date-range {
    margin-left: 5px !important;
  }

  .pwa-btn {
    position: fixed !important;
    bottom: 7% !important;
    right: 5%;
  }

  .e-schedule .e-timeline-view .e-appointment .e-appointment-details,
  .e-schedule .e-timeline-month-view .e-appointment .e-appointment-details {
    padding: 0px;
  }

  body,
  html {
    overflow-y: hidden;
  }


  element.style {
    left: 0px;
    top: 58px;
    z-index: 1304;
  }

  .client-pwa .e-popup.e-popup-open {
    margin-left: 20px;
  }

  .client-pwa .e-popup.e-popup-open {
    top: 65px !important;
  }

  .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-1uftnx2-MuiButtonBase-root-MuiMenuItem-root {
    min-height: 0px !important;
  }

  .css-amwlrc {
    min-height: 0px !important;
  }


}

/* .e-schedule-table.e-resource-column-table.e-auto-height tbody tr {
    height: 125px !important;
  } */
/* .e-schedule .e-timeline-month-view .e-appointment {
    height: 115px !important;
  } */
/* } */
.rs-btn-subtle:hover,
.rs-btn-subtle:focus {
  color: #575757 !important;
  background-color: #F8F8F8 !important;
}

.rs-btn-icon.rs-btn-xs>.rs-icon {
  font-size: 15px;
}

.bookingTotal {
  display: inline-block;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #3F434A !important;
  margin-top: 22px !important;
  margin-right: 5px !important;
}

.rs-calendar-table-cell-selected,
.rs-calendar-table-cell-in-range {
  position: relative;
}

.rs-calendar-table-cell-selected::before,
.rs-calendar-table-cell-in-range::before {
  content: '';
  display: block;
  width: 100%;
  margin-top: 4px;
  height: 24px;
  position: absolute;
  z-index: 0;
  top: 0;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content {
  z-index: 1;
  position: relative;
}

.rs-calendar-table-cell-in-range::before {
  background-color: rgba(221, 235, 247, 0.5);
}

.rs-calendar-table-cell-selected-start::before {
  background: var(--selectedStart);
}

.rs-calendar-table-cell-selected-end::before {
  background: var(--selectedEnd);
}

.rs-calendar-table-cell-in-range:hover::before {
  display: none;
}

.rs-calendar-table-cell.rs-calendar-table-cell-selected.rs-calendar-table-cell-selected-start.rs-calendar-table-cell-selected-end::before {
  background: transparent;
}

.e-schedule-toolbar-container {
  height: 0 !important;
  min-height: 0;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items.e-tbar-pos {
  height: 0 !important;
  min-height: 0 !important;
}

/* @media only screen and (min-width: 1100px) {
  .e-resource-column-wrap {
    height: 505px !important;
  }

  .e-content-wrap {
    height: 520px !important;
  }
} */


/* .e-schedule-toolbar-container.e-schedule-toolbar.e-toolbar-items.e-toolbar-left {
  visibility: visible !important;
} */

.e-toolbar-item.e-date-range {
  position: absolute !important;
  width: fit-content !important;
  z-index: 1 !important;
  margin-left: 95px !important;
}

.e-header-month {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #3F434A !important;
  padding: 5px;
  font-family: 'poppins' !important;
  margin: 5px;
}

.e-header-month-cell {
  padding: 10px !important;
}

.e-indicator.e-icons.e-left-icon {
  display: none !important;
}

.e-header-month-cell {
  border-bottom-width: inherit !important;
  border-left-width: inherit !important;
}

.e-header-month-cell.e-busy-weekend.backendClass.e-disableCell {
  background: #fff !important;
}

.e-resource-left-td {
  border-right-width: inherit !important;
}

/* .e-schedule-table.e-outer-table tbody tr:nth-child(2){
  position: relative;
  overflow-y: scroll;
  overflow-x: scroll;

}

.e-schedule-table.e-outer-table tbody > tr:nth-child(2) > td:nth-child(1){
  position: absolute;
  z-index: 1;
}

.e-schedule-table.e-outer-table tbody > tr:nth-child(2) > td:nth-child(2){
  position: absolute;
  overflow-x: scroll;

} */

/* .e-schedule-table.e-outer-table tbody tr:nth-child(2){
  position: relative;
  overflow-y: scroll;
}

.e-schedule-table.e-outer-table tbody > tr:nth-child(2) > td:nth-child(1){
  position: absolute;
  z-index: 1;
}

.e-schedule-table.e-outer-table tbody > tr:nth-child(2) > td:nth-child(2){
  position: absolute;
} */

.e-calendar .e-content.e-year td>span.e-day,
.e-calendar .e-content.e-decade td>span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td>span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td>span.e-day {
  font-weight: normal;
  height: 40px;
  line-height: 40px;
  width: 40px;
  border-radius: 25%;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
}

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  border-radius: 25%;
  color: #fff;
}

.e-calendar .e-content.e-year td>span.e-day:hover,
.e-calendar .e-content.e-decade td>span.e-day:hover,
.e-bigger.e-small .e-calendar .e-content.e-year td>span.e-day:hover,
.e-bigger.e-small .e-calendar .e-content.e-decade td>span.e-day:hover {
  font-weight: normal;
  height: 40px;
  line-height: 40px;
  width: 40px;
  border-radius: 25%;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
}

.e-calendar .e-content .e-year .e-cell:hover {
  font-weight: normal;
  height: 40px;
  line-height: 40px;
  width: 40px;
  border-radius: 25%;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
}

.e-calendar,
.e-bigger.e-small .e-calendar {
  background-color: #fff;
  box-shadow: 1px solid black;
  border-radius: 15px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.1);
}

.e-calendar .e-content.e-year .e-cell:hover .e-day {
  font-weight: normal;
  height: 40px;
  line-height: 40px;
  width: 40px;
  border-radius: 25%;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
}

.e-calendar .e-header.e-decade .e-title,
.e-calendar .e-header.e-year .e-title,
.e-bigger.e-small .e-calendar .e-header.e-decade .e-title,
.e-bigger.e-small .e-calendar .e-header.e-year .e-title {
  margin-left: 10px !important;
  font-family: 'Poppins';
  font-weight: 500 !important;
  font-size: 16px !important;
}

.e-calendar .e-content.e-decade .e-cell:hover .e-day {
  font-weight: normal;
  height: 40px;
  line-height: 40px;
  width: 40px;
  border-radius: 25%;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
}

.e-calendar,
.e-bigger.e-small .e-calendar {
  background-color: #fff;
}

.e-schedule-table.e-content-table.e-auto-height tbody tr td {
  height: 87px !important;
  min-height: 87px !important;
}

body,
html {
  overflow-y: hidden;
}

.e-popup.e-popup-open {
  top: 48px !important
}

.e-disableCell.backendClass.e-busy-weekend:hover {
  cursor: not-allowed !important;
}

.e-header-cells.e-date-header .topday {
  cursor: pointer;
}

.e-header-cells.e-date-header path {
  fill: #8A9099;
}

.e-header-cells.e-date-header .is_active {
  color: #007AFF;
}

.e-header-cells.e-date-header .is_today .topdayContent {
  background: #007AFF;
  color: white;
}

.e-header-cells.e-date-header .topday:hover .is_today .topdayContent p {
  color: white;
}

.e-header-cells.e-date-header .is_active * {
  color: inherit;
}

.e-header-cells.e-date-header .is_active path {
  fill: #007AFF;
}

.e-header-cells.e-date-header .arrow-icon {
  opacity: 0;
  position: absolute;
  bottom: auto;
  right: -20px;
  color: #007AFF;
}

.e-header-cells.e-date-header .topday:hover .arrow-icon,
.e-header-cells.e-date-header .is_active .arrow-icon {
  opacity: 1;
}

.e-schedule .e-timeline-view .non-active-day.e-work-cells:hover:not(.e-resource-group-cells),
.e-schedule .e-timeline-month-view .non-active-day.e-work-cells:hover:not(.e-resource-group-cells) {
  background: #F2F2F3 !important;
}

.e-work-cells.e-child-node.e-work-days.backendClass.non-active-day,
.e-schedule .backendClass.e-timeline-view .non-active-day.e-work-cells:hover:not(.e-resource-group-cells),
.e-schedule .backendClass.e-timeline-month-view .non-active-day.e-work-cells:hover:not(.e-resource-group-cells) {
  background: repeating-linear-gradient(45deg, white, #F2F2F3 2px, #F2F2F3 2px, #F2F2F3 8px) !important;
  pointer-events: none;
}
