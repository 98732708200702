.signup-background {
  background-image: url("../../assets/signup_laptop.png");
  background-repeat: no-repeat !important;
  background-size: cover !important;
  overflow-y: scroll;
}

@media (max-width:899px) {
  .signup-background {
    background-image: url("../../assets/tablet.png");
    height: 100vh !important;
  }

}

@media (max-width:640px) {
  .signup-background {
    background-image: url("../../assets/mobile.png");
    height: 100vh !important;
  }
}

.verify-background {
  background-image: url("../../assets/signup_laptop.png");
  background-repeat: no-repeat !important;
  background-size: cover !important;
  overflow-y: scroll;
}

@media (max-width:899px) {
  .verify-background {
    background-image: url("../../assets/tablet.png");
    height: 100vh !important;
  }

}

@media (max-width:640px) {
  .verify-background {
    background-image: url("../../assets/mobile.png");
    height: 100vh !important;
  }
}