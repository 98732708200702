.blocker {
  margin-top: 7rem;
  padding: 1rem;
}

.blocker_h1,
.blocker_p {
  font-family: 'Poppins';
  text-align: center;
}

.blocker_h1 {
  font-size: 2rem;
}

.blocker_p {
  font-size: 1.5rem;
}