body,
html {
  height: 100%;
  margin: 0;
}

.mainLogo {
  width: 92px;
  margin-top: 20px;
  margin-left: 20px;
}

.loginText {
  float: right;
  margin-top: 20px !important;
  margin-right: 20px !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 42px !important;
  color: #304FFD;
  cursor: pointer;
}

.loginText:hover {
  text-decoration: underline;
  color: #94a2f3 !important;
}

.backgroundImage {
  height: 100vh !important;
  background-image: url("../../assets/background.png");
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.iframeParent {
  height: 0px;
  padding-bottom: 43.25%;
  overflow: hidden;
  position: relative;
  z-index: 0;
  margin-top: 82px;
}

.tabletIframe {
  position: absolute;
  top: 0;
  width: 84%;
  height: 100%;
}

.sigupButton {
  background-color: #304FFD;
}

.mainTextLanding {
  font-weight: 700px !important;
  color: #3F434A !important;
  font-size: 5rem;
}

.mainsubLanding {
  font-family: 'Poppins';
  font-size: 22px;
  color: #3F434A !important;
}

.leftBox {
  margin-top: 85px;
  padding-inline: 105px;
}

@media (max-width:1260px) {
  .mainTextLanding {
    font-size: 4rem;
  }
}

@media (max-width:1326px) {
  .leftBox {
    margin-top: 85px;
    padding-inline: calc(15px + (100vw - 899px) * (-52 - 31) / (899 - 1663));
  }
}

@media (max-width:958px) and (min-width:900px) {
  .mainTextLanding {
    font-size: 4rem !important;
  }
}

@media (max-width:899px) {
  .backgroundImage {
    background-image: url("../../assets/tablet.png");
  }

  .tabletIframe {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    padding-inline: calc(15px + (100vw - 640px) * (-88 - 30) / (640 - 899));
  }

  .leftBox {
    margin-top: 30px;
    padding-inline: calc(15px + (100vw - 640px) * (-88 - 30) / (640 - 899));
  }
}

@media (max-width:640px) {
  .loginText {
    line-height: 71px !important;
    margin-top: 0 !important;
  }

  .mainLogo {
    width: 120px;
  }

  .backgroundImage {
    background-image: url("../../assets/mobile.png");
  }

  .mainTextLanding {
    font-size: 3rem;
  }

  .mainsubLanding {
    font-size: 16px !important;
    font-family: 'Poppins';
  }

  .leftBox {
    text-align: center;
    padding-inline: 45px;
    margin: 0;
    margin-top: 20px;
  }

  .tabletIframe {
    padding-inline: 35px;
  }
}