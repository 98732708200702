.agency {
  .custom-event-editor td {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .e-OwnerId-container,
  .e-input-wrapper.e-form-left,
  .e-all-day-container,
  .e-location-container {
    display: none;
  }

  .e-subject-container {
    width: 100% !important;
  }

  .e-work-cells.e-child-node.e-work-days.e-disableCell {
    background-color: #cdcccc42 !important;
  }

  form.example {
    width: 250px;
    position: fixed !important;
    z-index: 1;
    top: 30px;
  }

  form.example input[type=text] {
    padding: 10px;
    padding-left: 30px;
    font-size: 17px;
    border: 1px solid transparent;
    border-radius: 10px;
    float: left;
    background: #F8F8F8;
    width: 92%;
    height: 45px;
    margin-top: 27px;
    margin-left: 10px;
    margin-right: 10px;
  }

  form.example::after {
    content: "";
    clear: both;
    display: table;
  }

  .e-work-cells.e-child-node.e-work-days.backendClass {
    background: repeating-linear-gradient(45deg, rgb(196, 188, 188), white 2px, white 2px, white 8px) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

  .resource-name a {
    background-color: transparent !important;
    float: left !important;
  }

  .freelacner-name {
    width: 107px;
    height: 27px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: black;
  }

  .freelacner-name:hover {
    color: rgb(72, 136, 255) !important;
    ;
  }

  .professtion_ {
    position: relative;
    width: fit-content;
    font-family: Poppins;
    font-weight: normal;
    font-size: 14px;
    color: #828282;
  }

  .day-rate {
    width: 53px;
    height: 18px;
    margin-top: 19px;
    margin-bottom: 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #828282;
  }

  .customBtn {
    position: absolute;
    top: 42px;
    left: 22px;
    font-size: 18px;
  }

  .customFilterBtn {
    position: absolute;
    top: 42px;
    left: 210px;
    font-size: 18px;
  }

  .ratings {
    position: relative;
    top: 5px;
    left: 77px;
  }

  .textWrap {
    overflow: hidden;
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #FFFFFF;
  }

  .topday {
    font-family: Poppins !important;
    width: 25px;
    text-align: center;
    text-align: center;
    text-transform: uppercase;
    color: #8a9099;
    font-size: 12px;
    line-height: 18px;
  }

  .e-schedule .e-timeline-view .e-resource-cells,
  .e-schedule .e-timeline-month-view .e-resource-cells {
    vertical-align: baseline;
  }
}

.appoint_text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 60px;
  height: 60px;
}

.modelHeading {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #3f434a;
}

.modelMsg {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #595f69;
  padding: 15px 50px 15px 0px;
}

.cancelBtn {
  border-color: #E8E9EB;
  background: white;
  border-radius: 15px;
  width: 110px;
  height: 40px;
  font-family: Poppins !important;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  text-align: center;
  color: #595F69 !important;
}

.Modelfooter {
  display: flex;
  justify-content: center;
}

.confirmBtn {
  width: 70px;
  margin-left: 10px;
  border-radius: 15px;
  background-color: #FD7972 !important;
  color: white !important;
  width: 110px;
  height: 40px;
  font-family: Poppins !important;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  text-align: center;
}

.e-selected-cell {
  background-color: #f5f5f5 !important;
}

table.e-schedule-table tr td:first-child,
table td .e-resource-column-wrap {
  width: 250px !important;
}

.e-schedule .e-timeline-view .e-resource-left-td,
.e-schedule .e-timeline-month-view .e-resource-left-td {
  width: 250px;
}

.star-container .widget-svg .star:hover {
  fill: rgb(254, 181, 11) !important;
}

.e-tbar-btn-text {
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  text-align: center !important;
  color: #3F434A !important;
}

.e-toolbar-item.e-date-range.e-overflow-show:hover>#e-tbr-btn_2>.e-tbar-btn-text,
.e-toolbar-item.e-date-range.e-overflow-show:hover>#e-tbr-btn_2>.e-btn-icon.e-icon-down-arrow.e-icons.e-icon-right {
  color: rgb(72, 136, 255) !important;
}

.e-btn-icon.e-icon-next.e-icons:hover {
  color: rgb(72, 136, 255) !important;

}

.e-btn-icon.e-icon-prev.e-icons:hover {
  color: rgb(72, 136, 255) !important;

}

.e-schedule-table>colgroup>col {
  width: 87px !important;
}

#logout-button {
  right: -0.5rem !important;
}

#btn {
  border: none;
  padding: 5px 15px;
  background-color: transparent;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #8A9099;

}

#btn:hover {
  background-color: rgb(224, 224, 224);
}

.e-work-cells.e-child-node {
  // height: 84px !important;
}

.e-toolbar-items.e-tbar-pos {
  background-color: white !important;
}

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: rgb(72, 136, 255) !important;
}

.e-resource-cells.e-child-node {
  // height: 285px !important;
}

.e-tbar-btn.e-tbtn-txt.e-control.e-btn.e-lib {
  background-color: transparent !important;
}

.e-tbar-btn.e-tbtn-txt.e-control.e-btn.e-lib:hover,
:focus {
  box-shadow: none !important;
}

.e-tbar-btn.e-control.e-btn.e-lib.e-icon-btn {
  background-color: transparent !important;
}

.e-tbar-btn.e-control.e-btn.e-lib.e-icon-btn:hover,
:focus {
  box-shadow: none !important;
}

.searchInput:focus {
  outline: rgb(72, 136, 255) !important;
  border-color: rgb(72, 136, 255) !important;
}

.empty-row {
  height: 87px !important;
}

.e-work-cells.e-child-node.e-work-days.agency_view {
  height: 87px !important;
  min-height: 87px !important;
}

.agencyDialog .MuiDialog-container .MuiPaper-root {
  border-radius: 12px !important;
}

.agencyBookingCard {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
  border-radius: 12px !important;
  margin-bottom: 10px !important;
}

.agencyDialogTitle {
  font-size: 22px !important;
  color: #3F434A !important;
}

.cardCompanyName {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #3F434A !important;
  margin: 0 !important;
}

.bookingDaysContainer {
  text-align: center;
  margin: auto !important;
  padding: 0 !important;
  margin-top: 10px !important;
}

.bookingDays {
  border-radius: 12px;
  margin: auto !important;
  width: 70px;
  height: 70px;
  display: table-cell;
  vertical-align: middle;
}

.bookingText {
  line-height: 1 !important;
}

.bookingDaysCount {
  font-size: 32px !important;
  color: #fff !important;
  margin: 0;
}

.bookingDaysText {
  font-size: 16px !important;
  color: #fff !important;
  margin: 0;
}

.managerName {
  font-size: 14px !important;
  color: #2F80ED !important;
  text-transform: capitalize !important;
}

.dateText {
  color: #8A9099 !important;
  font-size: 14px !important;
}

.projectNameText {
  color: #3F434A !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}

.projectDescriptionText {
  color: #8A9099 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  text-align: justify;
}

.rateText {
  color: #8A9099 !important;
  font-size: 10px !important;
}

.totalRateContainer {
  padding-top: 0 !important;
}

.totalRateText {
  font-weight: 600 !important;
  color: #8A9099 !important;
  font-size: 12px !important;
}

.location {
  font-size: 12px !important;
  color: #2F80ED !important;

}

.mint_association {
  height: 45px;
}

.dateTextContainer,
.projectDescriptionContainer {
  padding-top: 0px !important;
}

.e-schedule-table.e-resource-column-table.e-auto-height tbody tr {
  height: 85px !important;
  min-height: 85px !important;
  box-sizing: border-box !important;
}

.e-resource-cells.e-child-node,
.e-schedule-table.e-content-table.e-auto-height tbody tr,
.e-work-cells.e-child-node.agency_view {
  height: 87px !important;
  min-height: 87px !important;
  box-sizing: border-box !important;
}


@media only screen and (max-width: 700px) {

  table.e-schedule-table tr td:first-child,
  table td .e-resource-column-wrap {
    width: 160px !important;
  }

}

@media only screen and (min-width: 800px) {

  .template-wrap .resource-details {
    height: 86px !important;
    min-height: 86px !important;
  }

}