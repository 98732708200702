.formLabel,
.loginHeading {
  font-family: "Poppins";
}

.formLabel {
  color: #08ffd1;
}

.register-background {
  height: 100vh !important;
  background-image: url("../../assets/background.png");
  background-repeat: no-repeat !important;
  background-size: cover !important;
  overflow-y: auto;
}

.inputField input {
  height: 40px;
  padding: 0px 10px;
  border: lightgrey 1px solid;
  border-radius: 15px;
  font-size: 14px;
  font-family: "poppins";
}

.font-14 {
  font-size: 14px !important;
}

.togglePassword {
  position: absolute;
  right: 0;
}

.forgotLink {
  margin: 4px 0 !important;
}

.signupText {
  font-size: 14px !important;
  padding: 2px 3px;
}

.MuiTextField-root p {
  color: red !important;
  font-size: 10px;
}

.ratePerDay #rate-helper-text {
  position: absolute;
  top: 95%;
}

.conditionText {
  font-size: 14px !important;
  color: #304FFD !important;
  text-decoration: none;
  line-height: 0 !important;
}

*:focus {
  outline: none !important;
}

.rememberMe {
  background-color: #08ffd1;
  width: "16px";
  height: "16px";
}

.alert {
  font-size: 14px !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.cardContainer {
  display: flex !important;
  justify-content: center !important;
  background-color: white !important;
  margin: auto;
  position: absolute;
  height: fit-content !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.cardBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: absolute;
  height: fit-content !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white !important;
  border-radius: 20px !important;
}

.cardHeader {
  font-size: 28px !important;
  font-weight: 500 !important;
  padding-bottom: 36px;
  text-align: center;
  color: #3F434A !important;
}

.cardHeader h1 {
  font-size: 28px !important;
  font-weight: 500 !important;
  text-align: center;
  color: #3F434A !important;
}

.cardDescription {
  font-size: 18px !important;
  font-weight: 500 !important;
  text-align: left;
  color: #3F434A !important;
}

.cardDescription {
  font-size: 18px !important;
  font-weight: 500 !important;
  text-align: left !important;
  color: #3F434A !important;
}

.cardFooter {
  color: #8A9099 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.cardLink {
  color: #304FFD !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-decoration: none !important;
}

.termsAndConditionsHelperText {
  color: red !important;
  font-size: 10px !important;
}

.signupHeader {
  color: "#101828" !important;
  text-align: center !important;
  font-weight: 600 !important;
  font-style: normal !important;
}

.cardShadow {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important;
}

.signup-background {
  background-image: url("../../assets/signup_laptop.png");
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 100vh
}

@media (max-width:899px) {
  .signup-background {
    background-image: url("../../assets/tablet.png");
    height: 100vh !important;
  }

}

@media (max-width:640px) {
  .signup-background {
    background-image: url("../../assets/mobile.png");
    height: 100vh !important;
  }

  .signupHeader {
    font-weight: 600 !important
  }
}